import './explorer.css'
import './explorer-overrides.css'

import FileCopyIcon from '@mui/icons-material/FileCopy'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import SyncAltIcon from '@mui/icons-material/SyncAlt'
import { Box, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { IntlProvider } from 'react-intl'

import { BoxAccessDetails, Engagement, EngagementParticipant } from '@/domain/engagements'
import { instrumentation } from '@/helpers'
import { getBoxAccessToken } from '@/helpers/services/backendGateway'

const { ContentExplorer, BoxItem } = require('box-ui-elements')

type BoxItem = {
  id: string
  size: number
  parent?: BoxItem
}

// https://opensource.box.com/box-ui-elements/#/Elements/ContentExplorer -> Props
type BoxUiOverrides = {
  canCreateNewFolder?: boolean
  canDelete?: boolean
  canDownload?: boolean
  canPreview?: boolean
  canRename?: boolean
  canUpload?: boolean
}

export type Props = {
  boxDetails: BoxAccessDetails | null
  engagement: Engagement
  boxUiOverrides?: BoxUiOverrides | null
  isExpanded: boolean
  onPreviewChanged?: (isPreviewOpen: boolean) => void
}

export default function ContentExplorerOrNothing({
  boxDetails,
  engagement,
  boxUiOverrides,
  isExpanded,
  onPreviewChanged,
}: Props): JSX.Element | null {
  const [boxAccessToken, setBoxAccessToken] = useState<string | null>()

  useEffect(() => {
    getBoxAccessToken().then(setBoxAccessToken)
  }, [])

  if (!boxDetails || !boxAccessToken) {
    return null
  }

  const handleUpload = (files: Array<BoxItem>) => {
    const fileIds = files.map((f) => f.id)
    instrumentation.addedFilesToBox(engagement.id, fileIds)
  }

  const restoreHeight = () => {
    if (onPreviewChanged) onPreviewChanged(false)
  }

  // based on https://stackoverflow.com/questions/20156453/how-to-detect-element-being-added-removed-from-dom-element
  const setMutationObserver = (viewer: HTMLElement) => {
    const observer = new MutationObserver((mutations) => {
      if (
        mutations.some(
          (mutation) => mutation.type === 'childList' && mutation.removedNodes.length > 0,
        )
      ) {
        restoreHeight()
      }
    })
    observer.observe(viewer, { childList: true })
  }

  const handlePreview = (payload: any) => {
    const { file, viewer } = payload
    instrumentation.viewedFileInBox(engagement.id, file.id)
    if (onPreviewChanged) {
      onPreviewChanged(true)
      setMutationObserver(viewer.containerEl)
    }
  }

  const handleDelete = (files: Array<BoxItem>) => {
    const fileIds = files.map((f) => f.id)
    instrumentation.deletedFilesInBox(engagement.id, fileIds)
  }

  const handleDownload = (files: Array<BoxItem> | BoxItem) => {
    const fileIds = files instanceof Array ? files.map((f) => f.id) : [files.id]
    instrumentation.downloadedFilesInBox(engagement.id, fileIds)
  }

  const partyName = otherPartyName(otherParty(engagement))

  return (
    <IntlProvider locale="en">
      <Typography
        variant={isExpanded ? 'h4' : 'body1'}
        color={isExpanded ? 'text.primary' : 'text.secondary'}
      >
        Use this box to share documents with {partyName}
      </Typography>
      <ContentExplorer
        token={boxAccessToken}
        rootFolderId={boxDetails.folderId}
        canShare={false}
        sortBy="date"
        sortDirection="DESC"
        logoUrl=""
        onUpload={handleUpload}
        onDelete={handleDelete}
        onDownload={handleDownload}
        onPreview={handlePreview}
        {...boxUiOverrides}
      />
    </IntlProvider>
  )
}

type DocumentSharingPreviewCardContentProps = {
  engagement: Engagement
}

export function DocumentSharingPreviewCardContent({
  engagement,
}: DocumentSharingPreviewCardContentProps): JSX.Element {
  const partyName = otherPartyName(otherParty(engagement))
  return (
    <Box sx={{ height: '100%' }}>
      <Typography variant="body1" color="text.secondary">
        Share documents with {partyName}
      </Typography>
      <Box
        sx={{
          width: '100%',
          height: '90%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            backgroundColor: (theme) => theme.palette.secondary.light,
            borderRadius: '50%',
            alignItems: 'center',
            justifyContent: 'center',
            width: {
              xs: '150px',
              md: '200px',
            },
            height: {
              xs: '150px',
              md: '200px',
            },
          }}
        >
          <FileCopyIcon
            sx={{
              color: (theme) => theme.palette.secondary.main,
              fontSize: {
                xs: '40px',
                md: '50px',
              },
            }}
          />
          <SyncAltIcon
            sx={{
              fontSize: {
                xs: '25px',
                md: '30px',
              },
              marginTop: {
                xs: '10px',
                md: '13px',
              },
              color: (theme) => theme.palette.primary.main,
            }}
          />
          <PeopleAltIcon
            sx={{
              color: (theme) => theme.palette.other.primaryLightDeep,
              fontSize: {
                xs: '70px',
                md: '80px',
              },
            }}
          />
        </Box>
      </Box>
    </Box>
  )
}

function otherParty(engagement: Engagement): EngagementParticipant | null {
  if (engagement.perspective === 'brand') return engagement.coman
  if (engagement.perspective === 'partner') return engagement.brand
  return null
}

function otherPartyName(party: EngagementParticipant | null): string {
  // fall back to "the other party on this engagement" in the unlikely case of engagement.perspective not being
  // brand nor partner (service provider?)
  return party ? party.companyName : 'the other party on this engagement'
}
