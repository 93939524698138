import { Company, FieldTypes } from 'partnerslate-models'
import React, { useEffect, useState } from 'react'

import { CompanyFieldCard, Paper } from '@/components'
import Strings from '@/constants'

import FieldEditor from './field-editor'
import styles from './styles.scss'

type Props = {
  company: Company
  userCompany: Company
  editing: boolean
  editingField: FieldTypes | undefined
  setEditingField: React.Dispatch<FieldTypes | undefined>
  setEditing: (editing: boolean) => void
}

function Sidebar(props: Props): JSX.Element {
  const [title, setTitle] = useState(Strings.profile.companyDetails)
  const { company, userCompany, editing, editingField, setEditingField, setEditing } = props
  const fields = company.profileFields

  const handleEditField = (field: FieldTypes) => {
    setEditingField(field)
    setTitle(Strings.general[field])
  }

  useEffect(() => {
    setTitle(editingField ? Strings.general[editingField] : Strings.profile.companyDetails)
  }, [editingField])

  const isMyCompany = company.id === userCompany.id
  const isOwnEditableCompany = company.isAdmin && isMyCompany

  return (
    <Paper className={styles.sideBar}>
      <header>
        <h3 className={styles.title}>{title}</h3>
      </header>
      <hr />
      {editingField ? (
        <FieldEditor field={editingField} />
      ) : (
        fields.map(({ field, value }) => {
          return (
            <CompanyFieldCard
              isOwnEditableCompany={isOwnEditableCompany}
              field={field}
              // hack to stop 0 from showing without messing with the default model stuff
              value={value || undefined}
              key={field}
              className={styles.fieldRow}
              editing={editing}
              setEditingField={handleEditField}
              setEditing={setEditing}
            />
          )
        })
      )}
    </Paper>
  )
}

export default Sidebar
