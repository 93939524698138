/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
import { Box, Button, Typography } from '@mui/material'
import { format } from 'date-fns'
import { Company, User } from 'partnerslate-models'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, useLocation } from 'react-router'
import { Dispatch } from 'redux'

import TestIds from '@/accessibility/test-ids'
import { StatefulIconAnchor } from '@/components'
import { CropArea } from '@/components/image-cropper'
import Strings, { Images, ImageTypes, ProductTypes, Routes } from '@/constants'
import { ChangePasswordFormValues } from '@/forms/change-password'
import { UpdateUserFormValues } from '@/forms/update-user'
import { instrumentation } from '@/helpers'
import { useFeatureFlag } from '@/helpers/featureFlags'
import { endpoints } from '@/helpers/services'
import { BillingPortalDataType } from '@/helpers/services/backendGateway'
import { useBillingPortal } from '@/helpers/services/queries'
import { ActionCreators, StoreState } from '@/redux'
import {
  changePasswordPayload,
  createCancelSubscriptionPayload,
  createCheckoutSessionPayload,
  deleteAccountPayload,
  deleteCardPayload,
  deleteUserPhotoPayload,
  updateCompanyPayload,
  updateUserPayload,
} from '@/redux/api-payloads'
import selectors from '@/selectors'

import TeamScreen from '../team'
import ChangePassword from './change-password'
import CompanyDetails from './company-details'
import PaymentSettings from './payment-settings'
import styles from './styles.scss'
import Subscriptions from './subscriptions'
import YourInfo from './your-info'

interface Props extends RouteComponentProps {
  user: User
  deleteAccount: () => void
  updateUser: (data: UpdateUserFormValues) => void
  photoMeta: { [key: string]: unknown } | null
  photoPending: boolean
  deleteCard: (data: { cardId: string; subscriptionId: string; companySlug: string }) => void
  uploadImage: (data: { image: File; crop?: CropArea; type: ImageTypes }) => void
  changePassword: (data: ChangePasswordFormValues) => void
  company: Company
  checkout: (
    product: ProductTypes | undefined,
    companySlug: string,
    mode: 'subscription' | 'setup',
  ) => void
  downgrade: (subscriptionId: string, slug: string) => void
  deleteImage: (id: string) => void
}

// eslint-disable-next-line no-shadow
enum Views {
  YOUR_INFO = 'YOUR_INFO',
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
  COMPANY_DETAILS = 'COMPANY_DETAILS',
  SUBSCRIPTIONS = 'SUBSCRIPTIONS',
  PAYMENT_SETTINGS = 'PAYMENT_SETTINGS',
  TEAM = 'TEAM',
  SUBSCRIPTION = 'SUBSCRIPTION',
}

const getViewfromPath = (location: string) => {
  let view = Views.YOUR_INFO
  if (location === Routes.ProfileSubscribe) {
    view = Views.SUBSCRIPTIONS
  } else if (location === Routes.ProfilePaymentSettings) {
    view = Views.PAYMENT_SETTINGS
  } else if (location === Routes.ProfileCompanyDetails) {
    view = Views.COMPANY_DETAILS
  } else if (location === Routes.ProfileChangePassword) {
    view = Views.CHANGE_PASSWORD
  } else if (location === Routes.ProfileTeam) {
    view = Views.TEAM
  } else if (location === Routes.Subscription) {
    view = Views.SUBSCRIPTION
  }
  return view
}

function Subscription() {
  const { data, isLoading, isFetching } = useBillingPortal()
  const enableCustomerBillingPortal = useFeatureFlag('enable_customer_billing_portal')

  useEffect(() => {
    instrumentation.viewSubscriptionPage()
  }, [])

  if (!enableCustomerBillingPortal) {
    return <div>To make changes to your subscription contact support@partnerslate.com</div>
  }

  if (isLoading || isFetching || !data) {
    return <div>Loading...</div>
  }

  const PLAN_DETAILS = {
    PRE_MSA: ['1 Connection Request Per Month', '3% Commission Fee'],
    FREE: ['1 Connection Request Per Month', '3% Commission Fee'],
    TRIAL: ['New Project Alerts', '3% Commission Fee'],
    STANDARD: [
      'New Project Alerts',
      'Unlimited Connection Requests',
      'Invitation to Trade Show Opportunities',
      'Get Discovered by National Brands and Retailers',
      '3% Commission',
    ],
    PRO: [
      'New Project Alerts',
      'Unlimited Connection Requests',
      'Get Discovered by National Brands and Retailers',
      'Early Access: Connect on Projects Before Standard Users',
      'Priority Access to Trade Show Opportunities',
      'Dedicated Account Manager',
      'Verified Profile Badge',
      '2.5% Commission',
    ],
    ENTERPRISE: [
      'New Project Alerts',
      'Unlimited Connection Requests',
      'Personalized Introductions to National Brands & Retailers',
      'Early Access: Connect on Projects Before Standard Users',
      'Priority Access to Trade Show Opportunities',
      'Dedicated Account Manager',
      'Outbound Sales Support',
      'Verified Profile Badge',
      'Custom Commission Agreements',
    ],
  }

  const isLegacyPlan = data.planId === 'LEGACY'
  const isTrialPlan = data.planId === 'TRIAL'
  const isPreMsaPlan = data.planId === 'PRE_MSA'

  if (isTrialPlan) {
    const trialExpirationDate = data.planExpiresAt
      ? ` until ${format(data.planExpiresAt, 'MMMM dd, yyyy')}`
      : ''
    PLAN_DETAILS.TRIAL.push(`Unlimited Connection Requests${trialExpirationDate}`)
  }

  const planInfo = isLegacyPlan
    ? PLAN_DETAILS.STANDARD
    : PLAN_DETAILS[data.planId as keyof typeof PLAN_DETAILS] || []

  type CtaConfiguration = {
    ctaText: string
    onClick: (e: React.MouseEvent) => void
  }

  const getCtaConfiguration = (
    billingPortalData: BillingPortalDataType,
    isTrial: boolean,
    isLegacy: boolean,
  ): CtaConfiguration => {
    let ctaPath = '/marketplace/coman-pricing'
    let ctaText = 'Get full access'

    let onClick = (e: React.MouseEvent) => {
      e.preventDefault()
      window.location.href = ctaPath
    }

    if (billingPortalData.isActivePaidSubscriber && billingPortalData.billingPortalSessionUrl) {
      ctaText = 'Manage plan'
      ctaPath = billingPortalData.billingPortalSessionUrl
      onClick = (e: React.MouseEvent) => {
        e.preventDefault()
        instrumentation.viewCustomerPortal()
        window.location.href = ctaPath
      }
    } else if (billingPortalData.planId === 'FREE') {
      ctaText = 'Get unlimited connections'
      ctaPath = '/marketplace/coman-pricing'
    } else if (isTrial || isLegacy || isPreMsaPlan) {
      ctaText = 'Get full access'
      ctaPath = '/marketplace/coman-pricing'
    }

    return { ctaText, onClick }
  }

  const { ctaText, onClick } = getCtaConfiguration(data, isTrialPlan, isLegacyPlan)

  return (
    <Box>
      <Typography variant="h4" textTransform="none">
        Your Plan: {data.planName}
      </Typography>
      <ul>
        {planInfo.map((info) => {
          return <li key={info}>{info}</li>
        })}
        {/* If the plan has a expiry date, explain what that means */}
        {isTrialPlan && data.planExpiresAt ? (
          <ul>
            <li>
              <Typography color="error">
                After your trial ends, you can only make 1 connection request per month
              </Typography>
            </li>
          </ul>
        ) : null}
      </ul>
      <Button variant="contained" onClick={onClick}>
        {ctaText}
      </Button>
    </Box>
  )
}

// eslint-disable-next-line max-lines-per-function
export function Profile({
  user,
  company,
  photoPending,
  photoMeta,
  updateUser,
  deleteAccount,
  deleteCard,
  uploadImage,
  changePassword,
  checkout,
  downgrade,
  deleteImage,
}: Props): JSX.Element {
  const location = useLocation()
  const [view, setView] = useState<Views>(getViewfromPath(location.pathname))
  useEffect(() => {
    setView(getViewfromPath(location.pathname))
  }, [location])

  return (
    <div className={styles.container}>
      <div className={styles.sidebar}>
        {!company.isMFG &&
        company.isAdmin &&
        company.card.valid &&
        !company.subscription.cancelled ? (
          <StatefulIconAnchor
            icon={Images.shoppingBag}
            path={Routes.ProfilePaymentSettings}
            onClick={() => setView(Views.PAYMENT_SETTINGS)}
            label={Strings.profile.paymentSettings}
            ariaLabel={Strings.profile.paymentSettings}
            selected={view === Views.PAYMENT_SETTINGS}
            testId={TestIds.Profile.PaymentSettings}
          />
        ) : null}
        {company.isAdmin ? (
          <StatefulIconAnchor
            icon={Images.briefcase}
            path={Routes.ProfileCompanyDetails}
            label={Strings.profile.companyDetails}
            ariaLabel={Strings.profile.companyDetails}
            selected={view === Views.COMPANY_DETAILS}
            testId={TestIds.Profile.CompanyDetails}
          />
        ) : null}
        <StatefulIconAnchor
          icon={Images.users}
          path={Routes.ProfileTeam}
          label="Team"
          ariaLabel="Team"
          selected={view === Views.TEAM}
          testId="Profile/Team"
        />
        <StatefulIconAnchor
          icon={Images.info}
          path={Routes.ProfileYourInformation}
          label={Strings.profile.yourInformation}
          ariaLabel={Strings.profile.yourInformation}
          selected={view === Views.YOUR_INFO}
          testId={TestIds.Profile.YourInfo}
        />
        <StatefulIconAnchor
          icon={Images.lock}
          path={Routes.ProfileChangePassword}
          label={Strings.profile.changePassword}
          ariaLabel={Strings.profile.changePassword}
          selected={view === Views.CHANGE_PASSWORD}
          testId={TestIds.Profile.ChangePassword}
        />
        <StatefulIconAnchor
          icon={Images.shoppingBag}
          path={Routes.Subscription}
          label="Your Subscription"
          ariaLabel="subscription"
          selected={view === Views.SUBSCRIPTION}
          // testId={TestIds.Profile.ChangePassword}
        />
      </div>
      <div className={styles.mainContent}>
        {view === Views.YOUR_INFO ? (
          <YourInfo
            user={user}
            submit={updateUser}
            deleteAccount={deleteAccount}
            photoPending={photoPending}
            photoMeta={photoMeta}
            uploadImage={uploadImage}
            deleteImage={() => deleteImage(user.profilePhotoId)}
          />
        ) : null}
        {view === Views.CHANGE_PASSWORD ? <ChangePassword submit={changePassword} /> : null}
        {view === Views.COMPANY_DETAILS ? (
          <CompanyDetails submit={updateCompanyPayload} company={company} />
        ) : null}
        {view === Views.SUBSCRIPTIONS ? (
          <Subscriptions checkout={checkout} company={company} downgrade={downgrade} />
        ) : null}
        {view === Views.PAYMENT_SETTINGS ? (
          <PaymentSettings
            card={company.card}
            checkout={() => checkout(undefined, company.slug, 'setup')}
            deleteCard={() =>
              deleteCard({
                cardId: company.card.id,
                subscriptionId: company.subscription.subscriptionId,
                companySlug: company.slug,
              })
            }
          />
        ) : null}
        {view === Views.TEAM ? <TeamScreen company={company} /> : null}
        {view === Views.SUBSCRIPTION ? <Subscription /> : null}
      </div>
    </div>
  )
}

const mapStateToProp = (state: StoreState) => {
  const user = selectors.user.getUser(state)
  const company = selectors.company.getCompany(state, user.company)
  return {
    user,
    company,
    photoPending:
      selectors.api.getPending(state, endpoints.attachCompanyImage) ||
      selectors.api.getPending(state, endpoints.uploadFile) ||
      selectors.api.getPending(state, endpoints.postFileUpload),
    photoMeta:
      selectors.api.getMeta(state, endpoints.attachCompanyImage) ||
      selectors.api.getMeta(state, endpoints.uploadFile) ||
      selectors.api.getMeta(state, endpoints.postFileUpload),
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  deleteAccount: () => dispatch(ActionCreators.api.makeRequest.dispatch(deleteAccountPayload())),
  changePassword: (data: ChangePasswordFormValues) =>
    dispatch(ActionCreators.api.makeRequest.dispatch(changePasswordPayload(data))),
  updateUser: (data: UpdateUserFormValues) =>
    dispatch(ActionCreators.api.makeRequest.dispatch(updateUserPayload(data))),
  uploadImage: (data: { image: File; crop?: CropArea }) =>
    dispatch(ActionCreators.user.uploadPhoto.dispatch(data)),
  deleteImage: (id: string) =>
    dispatch(
      ActionCreators.api.makeRequest.dispatch(
        deleteUserPhotoPayload({ id, type: ImageTypes.profile }),
      ),
    ),
  checkout: (
    product: ProductTypes | undefined,
    companySlug: string,
    mode: 'subscription' | 'setup',
  ) =>
    dispatch(
      ActionCreators.api.makeRequest.dispatch(
        createCheckoutSessionPayload({ product, companySlug, mode }),
      ),
    ),
  downgrade: (subscriptionId: string, slug: string) =>
    dispatch(
      ActionCreators.api.makeRequest.dispatch(
        createCancelSubscriptionPayload(subscriptionId, slug),
      ),
    ),
  deleteCard: (data: { cardId: string; subscriptionId: string; companySlug: string }) =>
    dispatch(ActionCreators.api.makeRequest.dispatch(deleteCardPayload(data))),
})

export default connect(mapStateToProp, mapDispatchToProps)(Profile)
