import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query'

import {
  BrandEditableEngagementStages,
  ComanEditableEngagementStages,
  Engagement,
  MessageChannelConnection,
  TodoKeysType,
} from '@/domain/engagements'
import { ComposedContentBlock, StructuredMessageChannelContents } from '@/domain/messages'
import { CheckoutPayload, CheckoutResponse } from '@/domain/stripe'
import { InviteInformation, TeamMembers } from '@/domain/team'
import {
  getBillingPortalUrl,
  getEngagement,
  getInviteInformation,
  getMessageChannelContents,
  getPartneredEngagements,
  getStripeCheckoutSession,
  getTeamMembers,
  parseEngagementPayload,
  postMessageToChannel,
  sendNdaReminder,
  updateEngagementStatus,
  updateTodoState,
} from '@/helpers/services/backendGateway'

function queryKeyForEngagementDetails(engagementId: string) {
  return ['engagementDetails', 'engagementId', engagementId]
}
// We parse the engagements coming from /partnered differently in both
// repos so make sure this key is different than the query key we use for the same
// endpoint in the ts-monorepo.
function queryKeyForPartneredEngagements() {
  return ['partneredEngagements']
}

export function useTeamMembers(companySlug: string): UseQueryResult<TeamMembers> {
  return useQuery(['teamMembers', companySlug], async () => {
    return getTeamMembers(companySlug)
  })
}

export function useBillingPortal() {
  return useQuery(
    ['billingPortal'],
    async () => {
      return getBillingPortalUrl()
    },
    {
      refetchOnWindowFocus: true,
    },
  )
}

export function usePartneredEngagements(): UseQueryResult<Engagement[]> {
  return useQuery(queryKeyForPartneredEngagements(), () => getPartneredEngagements())
}

export function useEngagementDetails(engagementId: string): UseQueryResult<Engagement | null> {
  return useQuery(queryKeyForEngagementDetails(engagementId), () => getEngagement(engagementId), {
    notifyOnChangeProps: ['data', 'isLoading', 'refetch'],
  })
}

export function useInviteInformation(inviteToken: string): UseQueryResult<InviteInformation> {
  return useQuery(['inviteInformation', 'inviteToken', inviteToken], () =>
    getInviteInformation(inviteToken),
  )
}

export function useMessageChannel(
  channel: MessageChannelConnection,
): UseQueryResult<StructuredMessageChannelContents> {
  return useQuery(['messages', channel.channelId], () => getMessageChannelContents(channel))
}

export type PostMessageToChannelVariables = {
  channel: MessageChannelConnection
  contents: ComposedContentBlock[]
}

export function usePostMessageToChannel(): UseMutationResult<
  void,
  unknown,
  PostMessageToChannelVariables
> {
  const queryClient = useQueryClient()

  return useMutation(
    ({ channel, contents }: PostMessageToChannelVariables) => {
      return postMessageToChannel(channel, contents)
    },
    {
      onSettled: (data, error, { channel }) => {
        queryClient.invalidateQueries(['messages', channel.channelId])
      },
    },
  )
}

export type CompletedTodoVariables = {
  engagementId: string
  todoKey: TodoKeysType
  completed: boolean
}

export function useUpdateTodoState(): UseMutationResult<
  TodoKeysType[],
  unknown,
  CompletedTodoVariables
> {
  const queryClient = useQueryClient()

  return useMutation(
    ({ engagementId, todoKey, completed }: CompletedTodoVariables) => {
      return updateTodoState(engagementId, todoKey, completed)
    },
    {
      onSettled: (data, error, { engagementId }) => {
        queryClient.invalidateQueries(queryKeyForEngagementDetails(engagementId))
      },
    },
  )
}

export type SendNdaReminderVariables = {
  engagementId: string
}
export function useSendNdaReminder(): UseMutationResult<
  Engagement,
  unknown,
  SendNdaReminderVariables
> {
  const queryClient = useQueryClient()

  return useMutation(
    ({ engagementId }: SendNdaReminderVariables) => {
      return sendNdaReminder(engagementId)
    },
    {
      onSettled: (data, error, { engagementId }) => {
        queryClient.invalidateQueries(queryKeyForEngagementDetails(engagementId))
      },
    },
  )
}

type UpdateEngagementStatusParams = {
  engagementId: string
  status: BrandEditableEngagementStages | ComanEditableEngagementStages
}
export function useUpdateEngagementStatus(): UseMutationResult<
  Engagement,
  unknown,
  UpdateEngagementStatusParams
> {
  const queryClient = useQueryClient()

  return useMutation(
    ({ engagementId, status }: UpdateEngagementStatusParams) => {
      return updateEngagementStatus(engagementId, status)
    },
    {
      onSuccess: (data, variables) => {
        const freshEngagement = parseEngagementPayload(data)

        queryClient.setQueryData<Engagement>(
          queryKeyForEngagementDetails(variables.engagementId),
          freshEngagement,
        )

        const stateEngagements = queryClient.getQueryData<Engagement[]>(
          queryKeyForPartneredEngagements(),
        )

        if (stateEngagements) {
          // update freshEngagement in list immediately
          const updatedEngagements = stateEngagements.map((previousEngagement) => {
            return previousEngagement.id === variables.engagementId
              ? freshEngagement
              : previousEngagement
          })
          queryClient.setQueryData(queryKeyForPartneredEngagements(), updatedEngagements)
        }
      },
      onSettled: (data, error, { engagementId }) => {
        queryClient.invalidateQueries(queryKeyForEngagementDetails(engagementId))
        queryClient.invalidateQueries(queryKeyForPartneredEngagements())
      },
    },
  )
}

export function useStripeCheckout(payload: CheckoutPayload): UseQueryResult<CheckoutResponse> {
  return useQuery(['stripe-checkout', payload], () => getStripeCheckoutSession(payload), {
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  })
}
