"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/*  eslint-disable camelcase */
const _1 = require(".");
const defaults_1 = require("./defaults");
class SubscriptionPlan extends _1.BaseModel {
    constructor(data = {}) {
        super();
        this.id = (0, defaults_1.defaultString)(data.id);
        this.name = (0, defaults_1.defaultString)(data.name);
        this.is_active_paid_subscriber = (0, defaults_1.defaultBoolean)(data.is_active_paid_subscriber);
    }
    get isActivePaidSubscriber() {
        return this.is_active_paid_subscriber;
    }
}
exports.default = SubscriptionPlan;
