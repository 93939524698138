import { createTheme, ThemeOptions, ThemeProvider } from '@mui/material/styles'
import React from 'react'

import theme from './muiTheme.json'
import muiThemeFuture from './muiThemeFuture.json'

// we need to do an `as` type assertion here to prevent TS from applying type widening to the
// string literals in our JSON, which was leading to type checking errors with the value of
// `h4.textTransform`, very similar like those reported here: https://github.com/frenic/csstype/issues/38
export const OUR_THEME = createTheme(theme as ThemeOptions)
export const FUTURE_THEME = createTheme(muiThemeFuture as ThemeOptions)

export function OurThemeProvider({ children }: { children?: React.ReactNode }): JSX.Element {
  return <ThemeProvider theme={OUR_THEME}>{children}</ThemeProvider>
}

export function FutureThemeProvider({ children }: { children?: React.ReactNode }): JSX.Element {
  return <ThemeProvider theme={FUTURE_THEME}>{children}</ThemeProvider>
}

declare module '@mui/material/styles' {
  interface Palette {
    other: {
      divider: string
      outlinedBorder: string
      standardInputLine: string
      primaryLightDeep?: string
    }
  }

  interface PaletteOptions {
    other?: {
      divider: string
      outlinedBorder: string
      standardInputLine: string
      primaryLightDeep?: string
    }
  }
}
